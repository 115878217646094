<!--
 * @Author: your name
 * @Date: 2020-10-19 15:58:31
 * @LastEditTime: 2020-10-27 17:33:12
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \GrandHyattc:\Users\25740\Desktop\jciweb\src\views\AuthPages\SignIn1.vue
-->
<template>
  <div class="SignIn1">
    <h1 class="title">{{ $t("Login.794@sign_in") }}</h1>
    <div class="mt-4">
      <div class="messageError" v-if="showMessage" v-html="message"></div>
      <div class="form-group">
        <label for="exampleInputEmail1">{{
          $t("Login.f65@email_address")
          }}</label>
        <input type="email" class="form-control mb-0" id="exampleInputEmail1" :placeholder="$t('Login.27e@enter_email')"
          v-model="form.email" />
      </div>
      <div class="form-group passwordInt">
        <label for="exampleInputPassword1">{{
          $t("Login.dfc@password")
          }}</label>
        <router-link :to="{ name: 'auth1.recoverPasswordBefore' }" class="float-right">{{
          $t("Login.4b0@forgot_password") }}?</router-link>
        <input type="password" class="form-control psd mb-0" id="exampleInputPassword1"
          :placeholder="$t('Login.dfc@password')" v-model="form.password" ref="password" @focus="tips"
          @blur="tipdown"/>
        <i ref="eye" v-if="!isIEbrowser" class="ri-eye-line" @click="changeType"></i>
        <div v-if="showTips" class="tip">
          <div class="tip-line"></div>
          <div class="tip-main">
            <!-- <span style="color: #ffba68"
                      ><i class="ri-file-warning-line"></i
                    ></span> -->
            <!-- <span>Password length must be at least 8 characters and must include at least 1 of the following: number,
                      upper case letter & lower case letter.</span> -->
            <span>{{ $t("Register.831@password_length_of_8") }}</span>
          </div>
        </div>
      </div>
      <!-- 验证码 -->
      <!-- <label> {{ $t("Login.33c@verification_code") }}</label>
      <div class="w-100 row mb-3 codeGroup m-0">
        <input class="codeInt col" type="text" v-model="form.captcha" />
        <img
          class="codeImg col"
          :src="codeImg"
          style="cursor: pointer"
          @click="captcha"
          alt="Picture captcha"
        />
      </div> -->
      <div class="d-inline-block w-100">
        <div class="custom-control custom-checkbox d-inline-block mt-2 pt-1">
          <input type="checkbox" class="custom-control-input" id="rememberMe" v-model="form.rememberMe" />
          <label class="custom-control-label" for="rememberMe">{{
            $t("Login.755@remember_me")
            }}</label>
        </div>
        <button type="submit" @click="handleClickLogin" @keyup.enter="handleClickLogin"
          class="btn btn-primary float-right">
          {{ $t("Login.794@sign_in") }}
        </button>
      </div>
      <div class="sign-info">
        <span class="dark-color d-inline-block line-height-2">
          {{ $t("Login.446@dont_have_an_account") }}?

          <router-link :to="{ name: 'auth1.sign-up1' }">{{
            $t("Login.aee@sign_up")
            }}</router-link>
        </span>
      </div>

      <div v-if="this.showId">
        <p style="font-size: 18px; padding: 10px; background: #f2f9ec; color: #7FBE50">
          Registration successful! Check your email (including your spam folder). If you do not receive it after 5
          minutes, click the link below.
        </p>
        <span style="float: right; color:#50b5ff;cursor:pointer" @click="reactivate()" type="primary">Resend
          registration email</span>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'SignIn1',
    data: () => ({
      timer: null,
      form: {
        email: '',
        password: '',
        rememberMe: false
        // captcha: "",
        // cache_key: "",
      },
      message: '',
      showMessage: false,
      codeImg: '',
      // saveInfo: {
      //   rememberMe: false,
      // },
      isIEbrowser: false,
      showId: '',
      showTips:false
    }),
    mounted() {
      this.getLocalPassword()
      this.enterKeyup()
      this.IEVersion()
      // this.captcha();
    },
    beforeDestroy() {
      // window.localStorage.removeItem('fromRouter')
    },
    destroyed() {
      
      this.enterKeyupDestroyed()
    },
    methods: {
      tips() {
        this.showTips = true;
      },
      tipdown() {
        this.showTips = false;
      },
      reactivate() {
        this.$http.reactivate({ email: this.showId }).then((res) => {
          if (res.status == 200) {
          }
        })
      },
      captcha() {
        this.$http.captcha().then((res) => {
          if (res.status == 200) {
            this.codeImg = res.data.captcha_img
            this.form.cache_key = res.data.cache_key
            // console.log(res);
          }
        })
      },
      IEVersion() {
        let userAgent = navigator.userAgent // 取得浏览器的userAgent字符串
        let isIE = userAgent.indexOf('compatible') > -1 // 判断是否IE浏览器
        let isEdge = userAgent.indexOf('Edg') > -1 // 判断是否IE的Edge浏览器
        if (isIE || isEdge) {
          this.isIEbrowser = true
        }
      },

      changeType() {
        if (this.$refs.password.type == 'text') {
          this.$refs.password.type = 'password'
          this.$refs.eye.style.color = '#ccc'
        } else {
          this.$refs.password.type = 'text'
          this.$refs.eye.style.color = '#50b5ff'
        }
      },
      enterKey(event) {
        const componentName = this.$options.name
        if (componentName == 'SignIn1') {
          const code = event.keyCode
            ? event.keyCode
            : event.which
              ? event.which
              : event.charCode
          if (code == 13) {
            this.handleClickLogin()
          }
        }
      },
      enterKeyupDestroyed() {
        document.removeEventListener('keyup', this.enterKey)
      },
      enterKeyup() {
        document.addEventListener('keyup', this.enterKey)
      },
      // 用户点击登录
      handleClickLogin() {
        const userLoginInfo = {
          ...this.form
        }
        this.$setlocalStorage('userLoginInfo', JSON.stringify(userLoginInfo))
        const data = {
          ...this.form
        }
        this.$http.userLogin(data).then((res) => {
          // this.captcha();
          // 如果勾选记住将保存登录信息
          if (this.form.rememberMe) {
            let obj = { ...this.form }
            this.$setlocalStorage('saveInfo', JSON.stringify(obj))
          } else {
            localStorage.removeItem('saveInfo')
          }
          if (res.status == 200) {
            let token = res.data.original.data.token
            let userInfo = res.data.original.data.user_info
            this.$setlocalStorage('token', token)

            this.$setlocalStorage('userInfo', JSON.stringify(userInfo))

            this.$store.commit(
              'Public/USEREINFO',
              res.data.original.data.user_info
            )
            this.$setlocalStorage(
              'time_zones',
              JSON.stringify(res.data.original.data.time_zones)
            )
            // this.$message({
            //   message: res.message,
            //   type: "success",
            // });
            let fromRouter = window.localStorage.getItem('fromRouter') || ''
            if (fromRouter) {
              this.$router.push(fromRouter)
              window.localStorage.removeItem('fromRouter')
            } else {
              this.$router.push('/newsfeeds')
            }
          } else if (res.status == 1036) {
            this.message = res.message
            this.showMessage = true
          } else if (res.status == 1003) {
            // 密码错误
            // this.$message({
            //   message: res.message,
            //   type: "warning",
            // });
          } else if (res.status == 1004) {
            // 2fa验证
            this.$router.push('/auth/authentication')
          } else if (res.status == 1005) {
            this.$router.push(`/auth/account-recovery-before`)
            // this.$router.push(`/auth/locked`);
            // 密码多次错误
            // this.$message({
            //   message: res.message,
            //   type: "warning",
            // });
          } else if (res.status == 1006) {
            this.$router.push(`/auth/account-recovery-before`)
            // 账号被冻结需要JCI工作人员解锁
            // this.$message({
            //   message: res.message,
            //   type: "warning",
            // });
          } else if (res.status == 0) {
            // this.captcha();
          }
        })
      },
      // 判断本地是否有记住密码
      getLocalPassword() {
        this.showId = window.location.href.split('email=')[1]
        if (this.showId) {
          this.showId = this.showId.replace('%40', '@')
        }
        let saveInfo = this.$getlocalStorage('saveInfo')
        if (saveInfo) {
          this.saveInfo = JSON.parse(saveInfo)
          this.form.email = this.saveInfo.email
          this.form.password = this.saveInfo.password
          this.form.rememberMe = this.saveInfo.rememberMe
        }
      }
    }

  }
</script>
<style lang="scss" scoped>
  @media (max-width: 578px) {
    .SignIn1 {
      /* padding-top: 40px; */
    }
  }

  .SignIn1 {
    .messageError {
      margin-top: 20px;
      margin-bottom: 20px;
      border-radius: 5px;
      padding: 10px;
      background: #fffcf3;
      color: #ffba68;
    }

    .title {
      margin-bottom: 83px;
    }

    .passwordInt {
      position: relative;
    }

    .tip {
      position: relative;

      .tip-line {
        position: absolute;
        width: 10px;
        height: 10px;
        left: 32%;
        bottom: 43px;
        border: 5px solid rgba(0, 0, 0, 0.01);
        border-top: 5px solid #aaa;
      }

      .tip-main {
        position: absolute;
        /* top: 10px; */
        bottom: 53px;
        right: 0px;
        width: 75%;
        background: #fff;
        text-align: center;
        border: 1px solid #aaa;
        border-radius: 5px;
      }
    }

    .ri-eye-line {
      position: absolute;
      top: 43px;
      right: 10px;
      color: #ccc;
    }

    .codeGroup {
      .codeImg {
        width: 20%;
        height: 45px;
      }

      .codeInt {
        padding: 0 15px;
        width: 40%;
        height: 45px;
        line-height: 45px;
        background: transparent;
        border: 1px solid #ced4da;
        border-radius: 10px;
      }
    }
  }
</style>